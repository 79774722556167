import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ProjectType } from "@prisma/client";
import Link from "next/link";
import { ButtonHTMLAttributes, DetailedHTMLProps, Fragment, ReactNode } from "react";
import { trackEvent } from "./analytics-wrapper";
import { gradientBackgroundClasses } from "./constants";
import { GradientBorder } from "./gradient-border";
import {
  imageTypeMetadata,
  imageTypeOrder,
  paidUserTypeOrder,
  projectTypeMetadata,
} from "./project-type-metadata";
import { classNames } from "@/helpers/tailwind-class";

interface ActionBaseProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: ReactNode;
  classes?: string;
}

interface ActionLinkProps extends ActionBaseProps {
  link: string;
}

const defaultClasses = "block cursor-pointer rounded-full px-4 py-2 text-neutral-50";

export const actionLinkBackground = "bg-[#3C1DFF] hover:bg-[#3C1DFF]/90 disabled:bg-slate-800";

export function ActionLink({ link, text, classes = "" }: ActionLinkProps) {
  return (
    <a className={classNames(defaultClasses, actionLinkBackground, classes)} href={link}>
      {text}
    </a>
  );
}

export function ActionLinkWithIcon({ text, link = "/create" }: { text: string; link?: string }) {
  return (
    <ActionLink
      text={
        <span className="flex">
          {text}
          <ArrowLongRightIcon className="ml-2 h-6 w-6" />
        </span>
      }
      link={link}
    />
  );
}

interface ActionButtonProps extends ActionBaseProps {
  onClick: () => void;
  showGradient?: boolean;
}

export function ActionButton({
  text,
  onClick,
  showGradient = true,
  classes = "",
  ...rest
}: ActionButtonProps) {
  return (
    <button
      className={classNames(
        defaultClasses,
        showGradient ? gradientBackgroundClasses : "bg-slate-800 hover:bg-slate-700",
        classes
      )}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  );
}

export function CreateVideoLink({ short = false }: { short?: boolean }) {
  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <MenuButton
        className="w-full"
        onClick={() => trackEvent({ eventName: "createProjectLink", action: "click" })}
      >
        <GradientBorder borderSize={4}>
          <div className="w-full rounded-md bg-slate-900 p-2 text-sm font-medium hover:bg-slate-800">
            <div className="flex h-full w-full items-center gap-2">
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
              {short ? null : "Create New"}
            </div>
          </div>
        </GradientBorder>
      </MenuButton>

      <MenuItems
        anchor={{ to: "top start", gap: "4px" }}
        transition
        className="origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <div className="rounded-lg bg-neutral-900 p-4 shadow-lg ring-1 ring-gray-900/5">
          <div className="grid grid-cols-2 gap-4">
            <Tools type="video" />
            <Tools type="image" />
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
}

function Tools({ type }: { type: "image" | "video" }) {
  const order = type === "image" ? imageTypeOrder : paidUserTypeOrder;
  const metadata = type === "image" ? imageTypeMetadata : projectTypeMetadata;
  return (
    <div className="flex flex-col gap-2">
      <div className="capitalize text-gray-400">{type}</div>
      <div className="grid grid-cols-1 gap-2">
        {order.map((tool) => {
          const data = metadata[tool as keyof typeof metadata];
          return (
            <MenuItem key={tool}>
              <Link href={data.link} className="block rounded-lg border border-slate-800">
                <div className="flex items-center gap-2 overflow-hidden rounded-lg py-0 pr-2 hover:bg-slate-800/50">
                  <div className="flex size-8 flex-none items-center justify-center bg-slate-800 group-hover:bg-slate-700">
                    <data.Icon className="size-full flex-shrink-0 text-gray-300 group-hover:text-indigo-400" />
                  </div>

                  <h3 className="text-xs leading-tight text-neutral-50">{data.name}</h3>
                  {data.needSubscription === false && (
                    <span className="rounded-full bg-primary px-2 py-0.5 text-[8px] capitalize text-white">
                      Free
                    </span>
                  )}
                </div>
              </Link>
            </MenuItem>
          );
        })}
      </div>
    </div>
  );
}
